import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from 'reactstrap/lib/Modal';
import Button from 'reactstrap/lib/Button';
import uuidv5 from 'uuid/v5';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import {
  APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
  EPO_UUIDV5_NAMESPACE,
  INSTANT_OFFER,
} from 'site-modules/shared/constants/appraisal/appraisal';
import { get, isEqual, noop, uniq, isEmpty, isNil } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  getVinsByAddress,
  buildPartnerOfferSquishStylesValidationPath,
  PartnerOfferModel,
  PartnerOfferEntities,
} from 'client/data/models/partner-offer-model';
import { getVinStylesData } from 'client/data/models/vehicle-vin';
import { CLOSE_VIN_DRAWER_EVENT } from 'site-modules/shared/components/find-vin-modal/appraisal-find-vin-drawer';
import { DRAWER_DETAIL_BUTTON_CTA_TEXT } from 'site-modules/shared/components/find-vin-modal/constants';
import { fireSquishVinTracking } from 'site-modules/shared/components/appraisal/trade-in-appraiser-steps/license-plate-and-vin-step/squish-vin-tracking';
import { getSyncDate } from 'site-modules/shared/components/profile/idm/idm-utils';
import { getIsOptimizedTraffic } from 'site-modules/shared/utils/appraisal/optimized-traffic';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { ApiMetrics, getMetricApiOptions, withMetrics } from 'client/utils/metrics-hoc';
import {
  fireAppraisalHowManyVinsPixel,
  fireAppraisalPixel,
  fireSelectVinPixel,
} from 'site-modules/shared/utils/appraisal/common-utils';

import { FindVinByAddressFormView } from './find-vin-by-address-form-view';
import { FindVinByAddressSubmittedView } from './find-vin-by-address-submitted-view';

import './find-vin-by-address.scss';

function getDefaultState() {
  return {
    isAddressFormSubmitted: false,
    isSubmittingAddressForm: false,
    isSubmittingVin: false,
    vehicles: null,
    selectedVin: null,
    vinStyles: null,
    overlayType: undefined,
    mmy: {},
    isModalOpened: false,
  };
}

export class FindVinByAddressUI extends Component {
  static propTypes = {
    handleAppraisalOnClick: PropTypes.func,
    trackVinEntry: PropTypes.func,
    updateVinAndContinue: PropTypes.func.isRequired,
    toggleVinLpForm: PropTypes.func,
    setVinForEligibility: PropTypes.func,
    onPartnerOfferVinEligibilityUpdate: PropTypes.func,
    updateStepsState: PropTypes.func,
    creativeId: PropTypes.string,
    isMobile: PropTypes.bool,
    vehicle: PropTypes.shape({
      make: PropTypes.shape({
        name: PropTypes.string,
      }),
      model: PropTypes.shape({
        name: PropTypes.string,
      }),
      year: PropTypes.shape({}),
    }),
    resetAddressForm: PropTypes.bool,
    isLandingPage: PropTypes.bool,
    useUniqId: PropTypes.bool,
    isOptimizedTraffic: PropTypes.bool,
    vinEligibility: PartnerOfferEntities.VinEligibility,
    ctaBtnColor: PropTypes.string,
    isAddressTab: PropTypes.bool,
    licensePlateAndVinForm: PropTypes.node,
    isForceCloseModal: PropTypes.bool,
    isEmo2507Chal: PropTypes.bool,
    apiMetrics: ApiMetrics,
  };

  static defaultProps = {
    creativeId: undefined,
    vehicle: {},
    resetAddressForm: false,
    isMobile: false,
    toggleVinLpForm: noop,
    handleAppraisalOnClick: noop,
    onPartnerOfferVinEligibilityUpdate: noop,
    setVinForEligibility: noop,
    trackVinEntry: noop,
    useUniqId: false,
    isOptimizedTraffic: false,
    isLandingPage: false,
    vinEligibility: null,
    ctaBtnColor: 'success',
    isAddressTab: false,
    updateStepsState: noop,
    licensePlateAndVinForm: null,
    isForceCloseModal: false,
    isEmo2507Chal: false,
    apiMetrics: undefined,
  };

  state = getDefaultState();

  componentDidMount() {
    window.addEventListener('pageshow', this.setDefaultStateForCachedPage);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      resetAddressForm,
      vinEligibility,
      onPartnerOfferVinEligibilityUpdate,
      isLandingPage,
      isForceCloseModal,
    } = this.props;
    const { vinStyles, isModalOpened } = this.state;

    if (resetAddressForm && !isEqual(resetAddressForm, prevProps.resetAddressForm)) {
      this.setDefaultState();
    }

    if (!isEmpty(vinEligibility) && !isEqual(vinEligibility, prevProps.vinEligibility)) {
      onPartnerOfferVinEligibilityUpdate();
      this.checkVinMismatchError();
    }

    if (isLandingPage && !isNil(vinStyles) && !isEqual(vinStyles, prevState.vinStyles)) {
      this.updateVinAndContinue(false);
    }

    if (isForceCloseModal && isModalOpened) {
      this.toggleModal();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pageshow', this.setDefaultStateForCachedPage);
  }

  onEscapeHatchCtaClick = event => {
    const { handleAppraisalOnClick, creativeId } = this.props;

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        subaction_name: TrackingConstant.VIEW_APPRAISAL,
        creative_id: creativeId,
        value: 'get_appraisal',
      },
    });
    handleAppraisalOnClick(event);
  };

  setDefaultState = () => {
    this.setState(getDefaultState());
  };

  setMmy = mmy => {
    this.setState({ mmy });
  };

  setSelectedVin = ({ target: { value } }) => {
    this.setState({
      selectedVin: value,
    });
  };

  setDefaultStateForCachedPage = ({ persisted }) => {
    if (persisted && this.props.isAddressTab) {
      this.setState({
        isAddressFormSubmitted: false,
        isSubmittingAddressForm: false,
        isSubmittingVin: false,
        vehicles: null,
        selectedVin: null,
        vinStyles: null,
        overlayType: undefined,
        isModalOpened: false,
      });
    }
  };

  getVins = async ({
    firstName,
    surname,
    city,
    zipCode,
    stateCode,
    address,
    apartment,
    createdDateUtc,
    quotebackId,
  }) => {
    const { make, year } = this.state.mmy;
    const addressObj = {
      quotebackId,
      firstName,
      surname,
      streetAddress: `${address} ${apartment}`,
      city,
      state: stateCode,
      zipCode,
      make,
      year: `${year}`,
      createdDateUtc,
    };
    return getVinsByAddress(addressObj);
  };

  updateVinAndContinue = hasVinMismatchError => {
    const {
      updateStepsState,
      updateVinAndContinue,
      trackVinEntry,
      creativeId,
      isLandingPage,
      isAddressTab,
    } = this.props;
    const { selectedVin, vinStyles, mmy } = this.state;
    const { make, model, year } = mmy;

    const processVinUpdate = () => {
      updateVinAndContinue(selectedVin, hasVinMismatchError, vinStyles, { make, model, year });
      updateStepsState({ isVinFromDrawer: true, hasVinMismatchError });
      EventToolbox.fireCustomEvent(CLOSE_VIN_DRAWER_EVENT, {});
      if (!hasVinMismatchError && !isLandingPage) {
        trackVinEntry(selectedVin, creativeId);
      }
    };

    if (isAddressTab && isLandingPage) {
      processVinUpdate();
    } else {
      this.setState({ isSubmittingVin: false }, () => processVinUpdate());
    }
  };

  checkVinMismatchError = () => {
    const { vinEligibility, creativeId } = this.props;
    const { mmy, selectedVin } = this.state;
    const { make, model, year } = mmy;
    const squishStyles = get(vinEligibility, 'squishStyles', []);
    const { makeNiceId, modelNiceId, year: stylesYear } = get(squishStyles, '[0]', {});
    const hasVinMismatchError = uniq([makeNiceId, make, modelNiceId, model, `${year}`, `${stylesYear}`]).length !== 3;
    if (!hasVinMismatchError) {
      fireSquishVinTracking(vinEligibility, selectedVin, creativeId);
    }
    this.updateVinAndContinue(hasVinMismatchError);
  };

  trackFindVinByAddressEvent = (subactionName, value, creativeId) =>
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: subactionName,
        creative_id: creativeId,
        value,
      },
    });

  handleFormSubmit = address => {
    this.setState({ isSubmittingAddressForm: true }, async () => {
      const { creativeId, toggleVinLpForm } = this.props;
      const { stateCode } = address;

      const createdDateUtc = getSyncDate();
      const city = address.city && address.city.toUpperCase();
      const quotebackId = uuidv5(`${city}|${createdDateUtc}`, EPO_UUIDV5_NAMESPACE).slice(0, 20);

      this.trackFindVinByAddressEvent(TrackingConstant.SUBMIT_ADDRESS_LOOKUP, 'Look up my VIN', creativeId);
      this.trackFindVinByAddressEvent(
        TrackingConstant.CUSTOMER_CONSENT_FOR_ADDRESS_LOOKUP,
        `quotebackId_${quotebackId}`,
        creativeId
      );
      this.trackFindVinByAddressEvent(TrackingConstant.SUBMIT_ADDRESS_STATE, stateCode, creativeId);

      const { vehicles } = await this.getVins({ ...address, createdDateUtc, quotebackId });
      fireAppraisalHowManyVinsPixel('address_lookup_vins_found', vehicles.length);

      if (!vehicles.length) {
        this.trackFindVinByAddressEvent(TrackingConstant.ADDRESS_LOOKUP, TrackingConstant.VIN_NOT_FOUND, creativeId);
        this.trackFindVinByAddressEvent(
          TrackingConstant.ADDRESS_LOOKUP_DISPLAY_RESULT,
          TrackingConstant.VIN_NOT_SHOWN,
          creativeId
        );
        toggleVinLpForm();
      } else {
        fireAppraisalPixel('Address');
        this.trackFindVinByAddressEvent(TrackingConstant.ADDRESS_LOOKUP, TrackingConstant.VIN_FOUND, creativeId);
        this.trackFindVinByAddressEvent(
          TrackingConstant.ADDRESS_LOOKUP_DISPLAY_RESULT,
          TrackingConstant.VIN_SHOWN,
          creativeId
        );
      }

      this.setState({
        isModalOpened: true,
        isAddressFormSubmitted: true,
        isSubmittingAddressForm: false,
        vehicles,
        selectedVin: get(vehicles, '[0].vin'),
      });
    });
  };

  continueWithSelectedVehicle = event => {
    event.persist();
    const { creativeId, setVinForEligibility, isLandingPage, updateStepsState, isEmo2507Chal, apiMetrics } = this.props;
    const { selectedVin } = this.state;
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: TrackingConstant.SUBMIT_VEHICLE,
        creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
        value: TrackingConstant.ADDRESS_LOOKUP,
      },
    });
    fireSelectVinPixel(selectedVin);
    setVinForEligibility(''); // Reset value
    // Reset isVinFromDrawer before setting to true.
    updateStepsState({ isVinFromDrawer: false });
    this.setState(
      () => ({ isSubmittingVin: true }),
      async () => {
        setVinForEligibility(selectedVin);

        if (isLandingPage) {
          const vinStyles = await getVinStylesData({
            vin: selectedVin,
            isEmo2507Chal,
            apiOptions: getMetricApiOptions(apiMetrics),
          });
          this.setState({
            vinStyles,
          });

          if (isEmo2507Chal) {
            EventToolbox.fireTrackAction({
              event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
              event_data: {
                action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
                action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
                creative_id: creativeId,
                action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
                subaction_name: TrackingConstant.SUBACTION_VIN_DECODE_STYLES,
                value: vinStyles?.length,
              },
            });
          }
        }
      }
    );
  };

  fireModalClosedTracking = () => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: TrackingConstant.CLOSE_POPUP,
        creative_id: this.props.creativeId,
        value: 'close',
      },
    });
  };

  toggleModal = () => {
    this.setState(({ isModalOpened }) => ({ isModalOpened: !isModalOpened }));
  };

  renderSubmittedView = isAddressTab => {
    const { ctaBtnColor, licensePlateAndVinForm, isLandingPage, isOptimizedTraffic } = this.props;
    const { vehicles, selectedVin, isSubmittingVin } = this.state;

    return (
      <FindVinByAddressSubmittedView
        vehicles={vehicles}
        selectedVin={selectedVin}
        setSelectedVin={this.setSelectedVin}
        isSubmitting={isSubmittingVin}
        continueWithSelectedVehicle={this.continueWithSelectedVehicle}
        ctaBtnColor={ctaBtnColor}
        isAddressTab={isAddressTab}
        licensePlateAndVinForm={licensePlateAndVinForm}
        escapeHatchCta={
          !isLandingPage &&
          !isOptimizedTraffic && (
            <Button
              color="link"
              className="text-primary-darker text-transform-none mt-1_5 p-0 text-start size-16"
              onClick={this.onEscapeHatchCtaClick}
              data-index={INSTANT_OFFER}
            >
              <span className="fw-normal">{DRAWER_DETAIL_BUTTON_CTA_TEXT.APPRAISE}</span>
            </Button>
          )
        }
      />
    );
  };

  render() {
    const {
      vehicle,
      creativeId,
      isLandingPage,
      ctaBtnColor,
      isMobile,
      useUniqId,
      resetAddressForm,
      handleAppraisalOnClick,
      isOptimizedTraffic,
      isAddressTab,
      isForceCloseModal,
    } = this.props;
    const { isAddressFormSubmitted, isSubmittingAddressForm, mmy, isModalOpened } = this.state;

    return (
      <div className="find-vin-by-address" data-tracking-parent={creativeId}>
        {!isAddressFormSubmitted || isAddressTab ? (
          <FindVinByAddressFormView
            onSubmit={this.handleFormSubmit}
            resetAddressForm={resetAddressForm}
            vehicle={vehicle}
            handleAppraisalOnClick={handleAppraisalOnClick}
            isLandingPage={isLandingPage}
            isMobile={isMobile}
            isOptimizedTraffic={isOptimizedTraffic}
            ctaBtnColor={ctaBtnColor}
            isSubmitting={isSubmittingAddressForm}
            useUniqId={useUniqId}
            setMmy={this.setMmy}
            mmy={mmy}
            isAddressTab={isAddressTab}
          />
        ) : (
          this.renderSubmittedView()
        )}
        {isAddressTab && (
          <Modal
            isOpen={isModalOpened && !isForceCloseModal}
            toggle={this.toggleModal}
            contentClassName="find-vin-by-address-modal-content pos-r"
            onClosed={this.fireModalClosedTracking}
          >
            <button
              type="button"
              className="close-btn pos-a p-0 d-flex align-items-center justify-content-center c-pointer"
              onClick={this.toggleModal}
            >
              <span
                data-testid="close-modal"
                className="icon-cross2 size-16 text-cool-gray-10"
                aria-label="Close modal"
              />
            </button>
            {this.renderSubmittedView(isAddressTab)}
          </Modal>
        )}
      </div>
    );
  }
}

export const stateToPropsConfig = {
  vinEligibility: bindToPath(
    ({ vehicle, vinForEligibility }) =>
      vinForEligibility && vehicle?.make?.name && vehicle?.model?.name && vehicle?.year?.year
        ? buildPartnerOfferSquishStylesValidationPath({
            vin: vinForEligibility,
            make: vehicle.make.name,
            model: vehicle.model.name,
            year: vehicle.year.year,
          })
        : null,
    PartnerOfferModel
  ),
};

const FindVinByAddressConnected = connectToModel(withMetrics(FindVinByAddressUI), stateToPropsConfig);

export function FindVinByAddress({ needsVinEligibility, ...restProps }) {
  const { ctaBtnColor, emo2507Recipe } = useAppraisalTabsContext();
  const [vinForEligibility, setVinForEligibility] = useState('');
  const isAppExtensionPage = useSelector(state => get(state, 'pageContext.isAppExtensionPage'));
  const isOptimizedTraffic = getIsOptimizedTraffic({ isAppExtensionPage });

  return (
    <FindVinByAddressConnected
      {...restProps}
      ctaBtnColor={ctaBtnColor}
      isOptimizedTraffic={isOptimizedTraffic}
      isEmo2507Chal={emo2507Recipe === 'chal1'}
      {...needsVinEligibility && { vinForEligibility, setVinForEligibility }}
    />
  );
}
